import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faPlayCircle,
  faCopy,
  faSync,
  faChevronRight,
  faEye,
  faCheck,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {

  constructor(props) {
    super(props)
    this.state={
      pass: '_nopss',
      showSuccess: false,
      length: 24,
      clipboard: true
    }
  }

  componentDidMount() {
    this.generatePass()
  }

  render() {
    return (
      <Layout container={true} isDark={true} hasDarkColorScheme={true}>
        <SEO title="Passwort Generator"></SEO>
        <div className="loosepage text-center">
          <h1>Sicheres Passwort </h1>
          <p style={{ maxWidth: 500, margin: "auto" }}>Erstellen Sie ein sicheres Zufallspasswort mit unserem Online-Passwortgenerator.</p>
          {this.state.showSuccess && <div style={{color:'green',marginTop:20,marginBottom:-10}}>Passwort wurde in die Zwischenablage kopiert.</div>}
        </div>
        <div className="bigform" style={{marginTop:-10}}>
          <input value={this.state.pass} readOnly/>
          {/*<input type="range" min={3} max={37} value={this.state.length} onChange={(e)=>{this.setState({length: e.target.value},this.generatePass)}} />*/}
          {this.state.clipboard && <button onClick={()=>{this.copyPass()}}><FontAwesomeIcon icon={faCopy}></FontAwesomeIcon> Kopieren </button>}
          <button onClick={()=>{this.generatePass()}}><FontAwesomeIcon icon={faSync}></FontAwesomeIcon> Erzeugen </button>
        </div>
        <br />
        <br />
        <br />
        <br />
      </Layout >
    )
  }

  copyPass() {


    navigator.clipboard.writeText(this.state.pass)
      .then(() => {
        this.setState({ showSuccess:true })
      })
      .catch(err => {
        console.log('Something went wrong', err);
      })


  }
  generatePass() {

    var pass = ''
    var str = 'ABCDEFGHJKLMNPQRSTUVWXYZ' +
      'abcdefghjkmnoipqrstuvwxyz123456789@#-$'

    var char = Math.floor(Math.random() * (str.length-4) + 1);
    pass += str.charAt(char)

    for (let i = 1; i <= (this.state.length-2); i++) {
      var char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char)
    }

    var char = Math.floor(Math.random() * (str.length-4) + 1);
    pass += str.charAt(char)

    this.setState({
      pass,
      showSuccess: false
    })

  }
}


